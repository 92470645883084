import React from "react"
import styled from "styled-components"
import QuestionMarkIcon from "../../../atoms/QuestionMarkIcon/QuestionMarkIcon"

const BenchmarkHeader = ({ width, title, subtitle, currentMonthValue, years }) => {
  return (
    <BenchmarkHeaderContainer width={width + 'px'}>
      <LeftSection>
        <Title>{title}</Title>
        {subtitle ? <QuestionMarkIcon title={title + ':'} text={subtitle}/> : null}
        <Subtitle>{years} year protocol</Subtitle>
      </LeftSection>
      <RightSection>
        <Title>Current Month: {currentMonthValue}%</Title>
        <Subtitle>({currentMonthValue}/100 patients compliant)</Subtitle>
      </RightSection>
    </BenchmarkHeaderContainer>
  )
}

export default BenchmarkHeader;

const BenchmarkHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width};
  margin-bottom: 10px;
  font-family: HelveticaNeueLTStd, sans-serif;
  font-weight: bold;
`

const LeftSection = styled.div`
  text-align: left;
  color: #3C3B3B;
`

const Title = styled.div`
  display: inline-block;
  font-size: 12pt;
`

const RightSection = styled.div`
color: #5978CF;
text-align: right;
`

const Subtitle = styled.div`
  font-size: 8pt;
`
