import React, { useEffect, useRef } from "react"

import {
  createSVG,
  drawYAxis,
  drawXAxis,
  drawYearLine,
  drawOptimalBenchmarkRange,
  drawCurrentMonthBorder
} from "./LineChart.utilities"

const LineChart = (
  {
    width,
    height,
    margin,
    currentYearData,
    previousYearData,
    benchmarkData,
  }) => {
  const refElement = useRef();

  useEffect(() => {
    const svg = createSVG({ref: refElement.current, margin, width, height});
    const y = drawYAxis({svg, width, height});
    const x = drawXAxis({svg, currentYearData, width, height});

    drawOptimalBenchmarkRange({svg, x, y, benchmarkData});

    //draw previous year
    drawYearLine({svg, x, y, yearData: previousYearData, color: '#B2B4B7'});
    //draw current year
    drawYearLine({svg, x, y, yearData: currentYearData, color: '#447B04'});

    drawCurrentMonthBorder({svg, x, height, currentYearData});
  });

  return (
    <div ref={refElement}/>
  )
}

export default LineChart;
