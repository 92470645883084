import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BenchmarkLineChart from "../components/organisms/BenchmarkLineChart/BenchmarkLineChart"

const currentYearData = [
  {month: 'APR', value: 58},
  {month: 'MAY', value: 86},
  {month: 'JUN', value: 52},
  {month: 'JUL', value: 75},
  {month: 'AUG', value: 45},
  {month: 'SEP', value: 50},
  {month: 'OCT', value: 55},
  {month: 'NOV', value: 51},
  {month: 'DEC', value: 59},
  {month: 'JAN', value: 50},
  {month: 'FEB', value: 60},
  {month: 'MAR', value: 56}
]

const previousYearData = [
  {month: 'APR', value: 53},
  {month: 'MAY', value: 56},
  {month: 'JUN', value: 50},
  {month: 'JUL', value: 48},
  {month: 'AUG', value: 54},
  {month: 'SEP', value: 55},
  {month: 'OCT', value: 50},
  {month: 'NOV', value: 48},
  {month: 'DEC', value: 42},
  {month: 'JAN', value: 45},
  {month: 'FEB', value: 55},
  {month: 'MAR', value: 61}
]

const benchmarkData = [
  {month: 'APR', min: 50, max: 70},
  {month: 'MAY', min: 52, max: 68},
  {month: 'JUN', min: 50, max: 70},
  {month: 'JUL', min: 48, max: 80},
  {month: 'AUG', min: 50, max: 70},
  {month: 'SEP', min: 43, max: 61},
  {month: 'OCT', min: 50, max: 70},
  {month: 'NOV', min: 48, max: 70},
  {month: 'DEC', min: 42, max: 70},
  {month: 'JAN', min: 38, max: 50},
  {month: 'FEB', min: 50, max: 70},
  {month: 'MAR', min: 50, max: 70}
]

const chart = {
  totalWidth: 1050,
  totalHeight: 400,
  title: 'Wellness Bloodwork',
  subtitle: 'Includes bloodwork with at least a CBC/Chemistry panel such as Wellness, Life Stage and Preanesthetic Panels',
  currentYearData: currentYearData,
  previousYearData: previousYearData,
  benchmarkData: benchmarkData,
  years: 1
}

const BenchmarkLineChartPage = () => (

  <Layout>
    <SEO title="Benchmark Line Chart" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <BenchmarkLineChart
      totalWidth={chart.totalWidth}
      totalHeight={chart.totalHeight}
      title={chart.title}
      subtitle={chart.subtitle}
      currentYearData={chart.currentYearData}
      previousYearData={chart.previousYearData}
      benchmarkData={chart.benchmarkData}
      years={chart.years}
    />
  </Layout>
)

export default BenchmarkLineChartPage
