import React from "react"
import styled from "styled-components"

import BenchmarkHeader from "./BenchmarkHeader/BenchmarkHeader"
import LineChart from "./LineChart/LineChart"

const BenchmarkLineChart = (
  {
    totalWidth,
    totalHeight,
    title,
    subtitle,
    currentYearData,
    previousYearData,
    benchmarkData,
    years
  }) => {
  // set the dimensions and margins of the benchmark graph
  const margin = { top: 20, right: 0, bottom: 40, left: 30 };
  const width = totalWidth - margin.left - margin.right;
  const height = totalHeight - margin.top - margin.bottom

  return (
    <Container>
      <BenchmarkHeader
        width={totalWidth - margin.right}
        title={title}
        subtitle={subtitle}
        currentMonthValue={currentYearData ? currentYearData[currentYearData.length - 1].value : 0}
        years={years}
      />
      <LineChart
        width={width}
        height={height}
        margin={margin}
        title={title}
        currentYearData={currentYearData}
        previousYearData={previousYearData}
        benchmarkData={benchmarkData}
      />
    </Container>
  )
}

export default BenchmarkLineChart;

const Container = styled.div`
  display: inline-block;
  padding-top: 20px;
`
