import * as d3 from "d3";

export const createSVG = ({ref, margin, width, height}) => {
  // clean svg
  d3.select(ref)
    .select('svg')
    .remove();

  return d3.select(ref)
    .append('svg')
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");
}

export const drawYAxis = ({svg, width, height}) => {
  const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);
  svg
    .append("g")
    .call(
      d3.axisLeft(y)
        .tickSize(-width)
        .tickSizeOuter(0)
        .tickFormat(d => d % 20 === 0 ? d + '%' : '')
    )
    .selectAll("line").style("stroke-opacity", "0.2");

  return y;
}

export const drawXAxis = ({svg, currentYearData, width, height}) => {
  let months = ['APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN' , 'FEB', 'MAR'];
  if (currentYearData) {
    months = currentYearData.map(item => item.month);
  }
  const x = d3.scaleBand().domain(months).range([0, width])
  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x).tickSize(0));

  return x;
}

export const drawOptimalBenchmarkRange = ({svg, x, y, benchmarkData}) => {
  if (!benchmarkData) {
    return;
  }

  const areaGenerator = d3.area()
    .x(d => x(d.month) + x.bandwidth()/2)
    .y0(d => y(d.min))
    .y1(d => y(d.max));

  svg.append("path")
    .datum(benchmarkData)
    .attr("d", areaGenerator)
    .attr("fill", "#DFEDC9")
    .attr("fill-opacity", "0.6");
}

export const drawYearLine = ({svg, x, y, yearData, color}) => {
  if (!yearData) {
    return;
  }

  // draw lines
  const lineFunction = d3.line()
    .x(function(d) { return x(d.month) + x.bandwidth()/2; })
    .y(function(d) { return y(d.value); });

  svg.append("path")
    .attr("d", lineFunction(yearData))
    .attr("stroke", color)
    .attr("stroke-width", 2)
    .attr("fill", "none");

  // draw circles
  var circles = svg.selectAll()
    .data(yearData)
    .enter()
    .append("circle");

  circles
    .attr("cx", d => x(d.month) + x.bandwidth()/2)
    .attr("cy", d => y(d.value))
    .attr("r", 3)
    .attr("fill", color)

}

export const drawCurrentMonthBorder = ({svg, x, height, currentYearData}) => {
  if (!currentYearData) {
    return;
  }

  svg.append("rect")
    .attr("x", x(currentYearData[currentYearData.length - 1].month))
    .attr("y", -20)
    .attr("width", x.bandwidth())
    .attr("height", height + 40)
    .attr("fill-opacity", "0")
    .attr("stroke", "#5978CF")
    .attr("stroke-width", "1");
}
